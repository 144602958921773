<template>
    <el-main>
        <el-form class="el-form-search" label-width="120px">
            <el-form-item label="订单编号：">
                <el-input size="small" placeholder="请输入订单编号" v-model="order_no" @input="input1"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名：">
                <el-input size="small" placeholder="请输入收货人姓名" v-model="receiver_name" @input="input2"></el-input>
            </el-form-item>
            <el-form-item label="收货人手机号：">
                <el-input size="small" placeholder="请输入收货人手机号" v-model="receiver_phone" @input="input3"></el-input>
            </el-form-item>
            <el-form-item label="下单时间：">
                <el-date-picker @change="getTime" v-model="timeVal" size="small" type="daterange" range-separator="~"
                    start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="商品名称：">
                <el-input size="small" placeholder="请输入商品名称/编号" v-model="goods_name" @input="input4"></el-input>
            </el-form-item>
            <el-form-item label="支付方式：">
                <el-select v-model="form.payment_code" size="small" placeholder="请选择">
                    <el-option v-for="item in pay_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单来源：">
                <el-select size="small" v-model="form.from" placeholder="请选择">
                    <el-option v-for="item in order_source" :key="item.val" :label="item.label"
                        :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订单类型：">
                <el-select size="small" v-model="form.order_type" placeholder="请选择" clearable>
                    <el-option v-for="item in order_type" :key="item.val" :label="item.label" :value="item.val"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="45px">
                <el-button type="primary" size="small" @click="serarch">搜索</el-button>
                <el-button size="small" @click="exportOrder">导出</el-button>
                <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
                <el-button plain size="small" @click="showSetPrinting = !0">打印订单设置</el-button>
            </el-form-item>
        </el-form>
        <el-tabs type="card" v-model="tab_type" @tab-click="tabClick(tab_type)">
            <el-tab-pane name="all">
                <span slot="label">全部订单（{{ order_num.state_all }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_pay">
                <span slot="label">待发货（{{ order_num.state_pay }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_send">
                <span slot="label">待收货（{{ order_num.state_send }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_new">
                <span slot="label">待支付（{{ order_num.state_new }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_success">
                <span slot="label">已完成（{{ order_num.state_success }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_send">
                <span slot="label">待开票（{{ order_num.state_send }}）</span>
            </el-tab-pane>
            <el-tab-pane name="state_cancel">
                <span slot="label">已关闭（{{ order_num.state_cancel }}）</span>
            </el-tab-pane>
        </el-tabs>
        <orderTable ref="orderTable" :content="form"></orderTable>
        <el-dialog title="打印订单设置" :visible.sync="showSetPrinting" width="35%">
            <el-form>
                <el-form-item label="是否打印："></el-form-item>
                <el-form-item>
                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                        @change="handleCheckAllChange">全选</el-checkbox>
                </el-form-item>
                <el-form-item>
                    <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
                        <el-checkbox v-for="city in cities" :label="city" :key="city">{{ city }}</el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showSetPrinting = !1">取 消</el-button>
                <el-button type="primary" @click="setPrintCon">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>
  
<script>
import orderTable from './components/orderTable';
import config from '@/util/config';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
let print_con = localStorage.getItem('printCon') || ['商品图片', '商品单价', '商品总价', '优惠金额', '快递运费', '商家备注'];
if (typeof print_con == 'string') {
    print_con = JSON.parse(print_con);
}
export default {
    data () {
        return {
            showSetPrinting: !1,
            checkAll: !1,
            checkedCities: print_con,
            cities: ['商品图片', '商品单价', '商品总价', '优惠金额', '快递运费', '商家备注'],
            isIndeterminate: !0,
            order_num: {},
            form: {
                page: 1,
                rows: 10,
                state_type: 'all',
                payment_code: 'whole', //支付方式
                feedback_state: 'whole', //售后状态
                from: 'whole', //订单来源
                keywords_type: '', //搜索类型
                keywords: '', //搜索内容
                create_time: [],
                group_sign: '',
                header_id: '',
                order_type: '',
            },
            order_no: '',
            goods_name: '',
            receiver_phone: '',
            receiver_name: '',
            tab_type: 'all',
            feedback_state: [
                {
                    val: 'whole',
                    label: '全部',
                },
                {
                    val: 'todo',
                    label: '申请售后',
                },
                {
                    val: 'closed',
                    label: '售后完成',
                },
            ],
            pay_type: [
                {
                    val: 'whole',
                    label: '全部',
                },
                {
                    val: 10,
                    label: '微信支付',
                },
                {
                    val: 20,
                    label: '支付宝支付',
                },
                {
                    val: 30,
                    label: '华为支付',
                },
                {
                    val: 40,
                    label: '余额支付',
                },
                {
                    val: 50,
                    label: '会员卡支付',
                },
            ],
            order_source: [
                {
                    val: 'whole',
                    label: '全部',
                },
                {
                    val: '1',
                    label: '微信',
                },
                {
                    val: '2',
                    label: '支付宝',
                },
                {
                    val: '3',
                    label: '快应用',
                },
                {
                    val: '4',
                    label: '抖音',
                },
                {
                    val: '5',
                    label: 'QQ',
                },
                {
                    val: '6',
                    label: '百度',
                },
            ],
            order_type: [
                {
                    val: 3,
                    label: '快递物流',
                },
                {
                    val: 4,
                    label: '商家自送',
                },
                {
                    val: 5,
                    label: '自提',
                }
            ],
            headerList: [],
            value: '',
            timeVal: '',
        };
    },
    components: {
        orderTable: orderTable,
    },
    created () {
        if (this.$route.query.tab_type) {
            this.tab_type = this.$route.query.tab_type;
            this.form.state_type = this.$route.query.tab_type;
        }
        if (this.$route.query.goods) {
            this.form.goods_id = this.$route.query.goods;
            this.form.room_id = this.$route.query.room;
        }
        this.getOrderNum();
        this.getHeader();
    },
    methods: {
        //获取团长
        getHeader () {
            this.$axios.post(this.$api.retail.headList, {
                rows: 10000
            }).then(res => {
                if (res.code == 0) {
                    this.headerList = res.result.list;
                } else {
                    this.$message.error(res.msg);
                }
            })
        },
        //导出订单
        exportOrder () {
            let content = this.form;
            let data = {
                page: content.page,
                rows: content.rows,
                create_time: content.create_time,
                group_sign: content.group_sign
            };
            if (content.state_type == 'group_state_pay') {
                data.group_state_type = 'group_state_pay';
                data.state_type = 'all';
            } else {
                data.state_type = content.state_type;
            }
            if (content.payment_code != 'whole') {
                data.payment_code = content.payment_code;
            }
            if (content.feedback_state != 'whole') {
                data.feedback_state = content.feedback_state;
            }
            if (content.from != 'whole') {
                data.from = content.from;
            }
            if (content.keywords) {
                data.keywords_type = content.keywords_type;
                data.keywords = content.keywords;
            }
            if (content.room_id) {
                data.room_id = content.room_id;
                data.goods_id = content.goods_id;
            }
            if (content.header_id) {
                data.header_id = content.header_id;
            }
            data.is_export = 1;
            this.$axios.post(industryName + this.$api.order.list, data).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        handleCheckAllChange (val) {
            this.checkedCities = val ? print_con : [];
            this.isIndeterminate = !1;
        },
        handleCheckedCitiesChange (value) {
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.cities.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
        },
        //设置打印内容
        setPrintCon () {
            this.showSetPrinting = !1;
            localStorage.setItem('printCon', JSON.stringify(this.checkedCities));
        },
        tabClick (val) {
            this.form.state_type = val;
            this.form.page = 1;
            this.form.rows = 10;
            this.$refs.orderTable.getList();
        },
        getOrderNum () {
            let content = this.form;
            content.page = 1;
            if (content.create_time.some(v => !v)) content.create_time = []
            let data = {
                order_type: content.order_type,
                page: content.page,
                rows: content.rows,
                create_time: content.create_time,
            };
            if (content.state_type == 'group_state_pay') {
                data.group_state_type = 'group_state_pay';
                data.state_type = 'all';
            } else {
                data.state_type = content.state_type;
            }
            if (content.payment_code != 'whole') {
                data.payment_code = content.payment_code;
            }
            if (content.feedback_state != 'whole') {
                data.feedback_state = content.feedback_state;
            }
            if (content.from != 'whole') {
                data.from = content.from;
            }
            if (content.keywords) {
                data.keywords_type = content.keywords_type;
                data.keywords = content.keywords;
            }
            if (content.room_id) {
                data.room_id = content.room_id;
                data.goods_id = content.goods_id;
            }
            if (content.header_id) {
                data.header_id = content.header_id;
            }
            if (this.$route.query.user_ids) data.user_ids = this.$route.query.user_ids;
            this.$axios.post(industryName + this.$api.order.num, data).then(res => {
                if (res.code == 0) {
                    this.order_num = res.result;
                }
            });
        },
        serarch () {
            this.getOrderNum();
            this.$refs.orderTable.getList();
        },
        cancelSearch () {
            this.goods_name = '';
            this.order_no = '';
            this.receiver_phone = '';
            this.receiver_name = '';
            this.form.keywords = '';
            this.form.keywords_type = '';
            this.timeVal = '';
            this.form.group_sign = '';
            this.form.create_time = [];
            this.form.state_type = 'all';
            this.form.payment_code = 'whole'; //支付方式
            this.form.feedback_state = 'whole'; //售后状态
            this.form.from = 'whole'; //订单来源
            this.form.header_id = '';
            this.getOrderNum();
            this.$refs.orderTable.getList();
        },
        getTime (val) {
            this.form.create_time = [];
            for (let i in val) this.form.create_time.push(val[i].getTime() / 1000);
            if (this.form.create_time[0] == this.form.create_time[1]) this.form.create_time[1] = this.form.create_time[1] + 86400;
        },
        input1 (val) {
            this.form.keywords_type = 'order_no';
            this.form.keywords = val;
            this.goods_name = '';
            this.receiver_phone = '';
            this.receiver_name = '';
        },
        input2 (val) {
            this.form.keywords_type = 'receiver_name';
            this.form.keywords = val;
            this.order_no = '';
            this.receiver_phone = '';
            this.goods_name = '';
        },
        input3 (val) {
            this.form.keywords_type = 'receiver_phone';
            this.form.keywords = val;
            this.order_no = '';
            this.goods_name = '';
            this.receiver_name = '';
        },
        input4 (val) {
            this.form.keywords_type = 'goods_name';
            this.form.keywords = val;
            this.order_no = '';
            this.receiver_name = '';
            this.receiver_phone = '';
        },
    },
};
</script>
  
<style lang="less" scoped>
.el-main {
    background: #fff;
}
</style>
  